<template>
  <div class="registered">
    <div class="registered-cont">
      <div class="logo">
        <!-- <img @click="goIndex" src="../../../public/icon/login/logo.png" alt=""> -->
        <svg class="icon logo-black" aria-hidden="true" v-if="shopName == null">
          <use xlink:href="#icon-meiyidalogo-black"></use>
        </svg>
        <h3 style="padding:30px" v-else>忘记密码</h3>
      </div>
      <div class="phone-item">
        <div class="phone">
          <input v-model="phone" oninput="if(value.length>15)value=value.slice(0,15)" type="text"
            placeholder="请输入手机号码">
        </div>
      </div>
      <div class="code-item">
        <div class="code-in">
          <div class="code">
            <input v-model="code" type="text" placeholder="请输入短信验证码">
          </div>
          <div class="code-btn" @click="getCode">
            {{stateCode}}
          </div>
        </div>
      </div>
      <div class="password-item">
        <div class="password">
          <input v-model="password" pattern="/(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{4,23}/"
            oninput="if(value.length>20)value=value.slice(0,20)" type="password" placeholder="请输入你要设置的密码">
        </div>
      </div>
      <div class="password-again-item">
        <div class="password-again">
          <input v-model="passwordAgain" pattern="/(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{4,23}/"
            oninput="if(value.length>20)value=value.slice(0,20)" type="password" placeholder="请确认密码">
        </div>
      </div>
      <div class="registered-btn">
        <el-button type="primary" @click="registerNow">确认重置</el-button>
      </div>
    </div>
  </div>
</template>
<script>
  import dump from "@/util/dump";
  export default {
    data() {
      return {
        stateCode: "获取验证码", //验证码获取按钮
        phone: '', //手机号
        code: '', //验证码
        password: '', //密码
        passwordAgain: '',//再次填写密码
        shopName:''
      }
    },
    created(){
      this.shopName = this.cookie.getCookie("shopName")
    },
    methods: {
      //确认重置
      registerNow() {
        const that = this
        if (!that.phone) {
          that.$message({
            message: '手机号不能为空！',
            type: 'error'
          })
          return false;
        }
        if (!that.code) {
          that.$message({
            message: '验证码不能为空！',
            type: 'error'
          })
          return false;
        }
        if (!that.password) {
          that.$message({
            message: '密码不能为空！',
            type: 'error'
          })
          return false;
        }
        if (!that.passwordAgain) {
          that.$message({
            message: '密码再次填写不能为空！',
            type: 'error'
          })
          return false;
        }
        if (that.password != that.passwordAgain) {
          that.$message({
            message: '再次填写密码错误！',
            type: 'error'
          })
          return false;
        }
        let para = {
          phone: that.phone,
          verCode: that.code,
          password: that.password
        }
        that.$api.resetPsd(para).then((res) => {
          if (res.status == 100) {
            that.$message({
              message: '密码重置成功',
              type: 'success'
            })
            setTimeout(() => {
              dump.link({
                type: 1,
                link: "/login",
                params: {
                id: 1,
                },
              })
            },3000)
          } else {
            that.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      },
      //获取验证码数据
      getCodeData() {
        const that = this
        if (!that.phone) {
          that.$message({
            message: '手机号不能为空！',
            type: 'error'
          })
          return false;
        }

        that.$api.appForgetPsd({
          phone: that.phone
        }).then((res) => {
          if (res.status == 100) {
            that.timeLess()
            that.$message({
              message: res.msg,
              type: 'success'
            })
            return false;
          } else {
            that.$message({
              message: res.msg,
              type: 'error'
            })
            return false;
          }
        })
      },
      //获取验证码
      getCode() {
        const that = this
        if (that.stateCode == "获取验证码") {
          that.getCodeData()
        } else {
          that.$message({
            message: '请勿重复点击！',
            type: 'error'
          })
        }

      },
      //获取验证码倒计时
      timeLess() {
        const that = this
        let unit = 's'
        let timeData = 60
        that.stateCode = timeData + unit
        let time = setInterval(() => {
          timeData--
          that.stateCode = timeData + unit
          if (timeData == 0) {
            clearInterval(time)
            that.stateCode = "获取验证码"
          }
        }, 1000);
      },
      //去首页
      goIndex() {
        this.$router.push('/')
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/view/forgotPassword/forgotPassword";
</style>
